<template>
  <div class="opt-payments">
    <div class="container">
      <hw class="title">{{ $t("optPayment") }}</hw>
      <ul class="opt-payments__items">
        <li class="opt-payments__item">{{ $t("optPaymentContent1") }}</li>
        <li class="opt-payments__item">
          {{ $t("optPaymentContent2") }}
          <a href="mailto:opt@magnum.kz">opt@magnum.kz</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "OptPayments",
};
</script>
